<template>
  <div class="userInfo">
    <div class="change-content">
      <el-form :label-position="labelPosition" ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="账号" prop="account">
              <el-input v-model="ruleForm.account" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item class="info-code" label="验证码" prop="verificationCode">
              <el-input maxlength="6" v-model="ruleForm.verificationCode" placeholder="请输入验证码"></el-input>
            </el-form-item>
            <p class="login-code">
              <span @click="!countdown ? verifyCodeHandle() : ''">{{countdown ? count + 's' : '获取验证码'}}</span>
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item class="pass-word" label="新手机号" prop="mobile">
              <el-input maxlength="11" v-model="ruleForm.mobile" placeholder="请输入新手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item class="pass-word" label="管理员姓名" prop="password">
              <el-input maxlength="10" v-model="ruleForm.managerName" placeholder="请输入管理员姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="register-button">
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          <el-button @click="handleClose">关闭</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getAccount, getName, getUserMobile, removeToken} from "@/utils/auth";
import * as api from "@/api";
import {toastMessage} from "@/utils/utils";
import {setUserPhone} from "@/utils/user";

export default {
  name: "userInfo",
  data() {
    return{
      labelPosition: 'top',
      count: '',
      countdown: false,
      timer:'',
      ruleForm: {
        account: getAccount(),
        mobile: getUserMobile(),
        managerName: getName(),
        verificationCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: "请输入11位手机号",
          }
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        managerName: [{required: true, message: '请输入管理员名称', trigger: 'blur'}],
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            mobile: this.ruleForm.mobile,
            managerName: this.ruleForm.managerName,
            verificationCode: this.ruleForm.verificationCode
          }
          api.login.editUserInfo(param).then((res) => {
            let result = res;
            if (result.code === 200) {
              toastMessage("操作成功","success");
              this.getUserInfo();
              this.handleClose();
              // removeToken();
              // this.$router.push({
              //   path: '/login'
              // })
            }
          })
        }
      })
    },
    //获取用户信息
    getUserInfo() {
      let params = {};
      api.menu.menu(params).then((res) => {
        if(res.code === 200) {
          let result = res.data;
          setUserPhone("T_mobile", result.mobile);
          setUserPhone("T_managerName", result.managerName);
        }
      })
    },
    verifyCodeHandle() {
      if(this.ruleForm.account === '') {
        toastMessage("请输入账号","warning");
        return
      }
      api.login.editUserInfoSms(this.ruleForm.account).then((res) => {
        if(res.code === 200) {
          this.getTimer();
          toastMessage(res.msg, "success");
        }
      })
    },
    //重置表单
    resetForm() {
      this.ruleForm = {
        mobile: "",
        managerName: "",
        verificationCode: ""
      }
      this.$refs["ruleForm" ].resetFields();
    },
    handleClose() {
      this.resetForm();
      this.$emit("handleClose")
    },
    /* 倒计时计数 */
    getTimer(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown = true;
        this.timer = setInterval(() => {
          if (this.count >1 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/login/userInfo";
.userInfo {
  width: 100%;
  .change-content {
    width: 100%;
    padding: 30px 30px 15px 30px;
    .info-code {
      margin-bottom: 10px;
    }
    .login-code {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3373FB;
      text-align: right;
      cursor: pointer;
    }
    .pass-font {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 40px;
    }
  }
}
</style>