<template>
  <div class="change">
    <div class="change-content">
      <el-form :label-position="labelPosition" ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="账号" prop="account">
              <el-input v-model="ruleForm.account" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="验证码" prop="verificationCode">
              <el-input maxlength="6"  v-model="ruleForm.verificationCode" placeholder="请输入验证码"></el-input>
            </el-form-item>
            <p class="login-code">
              <span @click="!countdown ? verifyCodeHandle() : ''">{{countdown ? count + 's' : '获取验证码'}}</span>
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item class="pass-word" label="新密码" prop="password">
              <el-input maxlength="20" type="text" v-model="ruleForm.password" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <p class="pass-font">密码必须包含大写、小写、数字组合（8-20字符）</p>
          </el-col>
        </el-row>
        <el-form-item class="register-button">
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          <el-button @click="handleClose">关闭</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import {toastMessage} from "@/utils/utils";
import {removeToken, getAccount} from "@/utils/auth";
import {updatePassword, updatePasswordSms} from "@/api/login";

export default {
  name: "changePassword",
  data() {
    return {
      labelPosition: 'top',
      count: '',
      countdown: false,
      timer:'',
      ruleForm: {
        account: getAccount(),
        password: '',
        verificationCode: ''
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)(?![0-9A-Z]+$)(?![0-9a-z]+$)[0-9A-Za-z]{8,20}$/,
            message: "密码必须包含大写、小写、数字组合，最低8位",
          }
        ],
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            account: this.ruleForm.account,
            password: this.ruleForm.password,
            verificationCode: this.ruleForm.verificationCode
          }
          api.login.updatePassword(param).then((res) => {
            let result = res;
            if (result.code === 200) {
              toastMessage("密码修改成功", "success")
              this.handleClose()
              // removeToken();
              // setTimeout(() => {
              //   this.$router.push({
              //     path: '/login'
              //   })
              // },500)
            }
          })
        }
      })
    },
    verifyCodeHandle() {
      if(this.ruleForm.account === '') {
        toastMessage("请输入账号","warning");
        return
      }
      api.login.updatePasswordSms(this.ruleForm.account).then((res) => {
        if(res.code === 200) {
          this.getTimer();
          toastMessage(res.msg, "success");
        }
      })
    },
    //重置表单
    resetForm() {
      this.ruleForm = {
        password: "",
        verificationCode: ""
      }
      this.$refs["ruleForm" ].resetFields();
    },
    handleClose() {
      this.resetForm();
      this.$emit("handleClose")
    },
    /* 倒计时计数 */
    getTimer(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown = true;
        this.timer = setInterval(() => {
          if (this.count >1 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/login/changePassword";
.change {
  width: 100%;
  .change-content {
    width: 100%;
    padding: 30px 30px 15px 30px;
    .login-code {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3373FB;
      text-align: right;
      cursor: pointer;
    }
    .pass-font {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 40px;
    }
  }
}
</style>