<template>
  <div class="app-main">
    <keep-alive>
      <!-- <transition name="fade"> -->
        <router-view v-if="$route.meta.keepAlive"/>
      <!-- </transition> -->
    </keep-alive>
    <!-- <transition name="fade"> -->
      <router-view v-if="!$route.meta.keepAlive" />
    <!-- </transition> -->
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .app-main {
    position: absolute;
    left: 254px;
    top: 94px;
    width: calc(100% - 254px);
    min-height: calc(100% - 94px);
    //overflow: scroll;
    background-color: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    padding: 20px 0px 10px 60px;
    z-index: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
      transition: opacity .3s
  }

  .fade-enter,
  .fade-leave-active {
      opacity: 0
  }
</style>