//科室接口
import * as request from '@/utils/request';
import * as api from '@/config/api'

//科室详情
export const getDetail = (data) => {
  return request.post(`${api.office.info}/${data}`, {});
}

//科室上传相关制度
export const uploadFile = (data) => {
  return request.post(api.office.upload, data);
}

//科室删除制度
export const delFile = (data) => {
  return request.post(`${api.office.delRule}/${data}`, {});
}

//科室获取更多相关制度
export const getRuleList = (data) => {
  return request.post(`${api.office.rule}/${data}`, {});
}

//科室获取更多操作制度
export const getOperationRuleList = (data) => {
  return request.post(`${api.office.operation}/${data}`, {});
}