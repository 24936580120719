<template>
  <div class='asideMenu'>
    <el-menu class="aside-bg" active-text-color="#1579FB" :default-active="activeMenu">
      <template v-for="(route, index) in routes">
        <!--表示 有一级菜单-->
        <router-link class="router-link-exact-active" v-if="!route.hidden && route.noDropdown"
          :to="route.path + route.children[0].path" :key="index">
          <el-menu-item class="dropItem" :index="route.path + route.children[0].path">
            <div class="icon-img">
<!--              <img class="pic" v-if="route.meta.icon" :src="route.meta.icon" />-->
              <span class="pic" v-if="route.meta.icon" :style="'background-image: url(' + route.meta.icon + ')'" />
            </div>
            <span class="menu-title" v-if="route.name" slot="title">{{ route.name }}</span>
          </el-menu-item>
        </router-link>

        <!--表示 有二级或者多级菜单 -->
        <el-submenu :popper-append-to-body="false" class="drop-menu"
          v-if="route.children && route.children.length >= 1 && !route.hidden && !route.noDropdown" :index="route.name"
          :key="index + route.path">
          <template slot="title">
            <div class="icon-img">
<!--              <img class="pic" v-if="route.meta.icon" :src="route.meta.icon" />-->
              <span class="pic" v-if="route.meta.icon" :style="'background-image: url(' + route.meta.icon + ')'" />
            </div>
            <span class="menu-title" v-if="route.name" slot="title">{{ route.name }}</span>
          </template>
          <!--直接定位到子路由上，子路由也可以实现导航功能-->
          <router-link class="router-link-me-active" v-for="(citem, cindex) in route.children" :to="citem.path"
            :key="cindex">
            <el-menu-item v-if="!citem.hidden" :index="citem.path">
              <div class="icon-img">
<!--                <img class="pic" v-if="citem.meta.icon" :src="citem.meta.icon" />-->
                <span class="pic" v-if="route.meta.icon" :style="'background-image: url(' + citem.meta.icon + ')'" />
              </div>
              <span class="menu-title-left" slot="title"> {{ citem.name }}</span>
            </el-menu-item>
          </router-link>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import * as api from "@/api/index";
import asyncRoutes from "../../router";
import dicData from '@/utils/dictionary';
export default {
  name: 'asideMenu',
  data() {
    return {
      routes: [],
      iconConfig: dicData.iconConfig,
    }
  },
  computed: {
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  methods: {
    getMenu() {
      let params = {};
      api.menu.menu(params).then((res) => {
        let result = res;
        if (result.code === 200) {
          this.routes = result.data.roleMenuList;
        }
      })
    },
  },
  created() {
    this.getMenu();
  }
}
</script>

<style lang='scss' scoped>
.asideMenu {
  width: 240px;
  position: fixed;
  top: 80px;
  left: 0px;

  .aside-bg {
    width: 100%;
    height: calc(100vh - 80px);
    background-color: #FFFFFF;
    border-right: solid 1px transparent;
    font-weight: bold;
    color: #666666;
    //padding-top: 10px;

    .dropItem,
    .drop-menu,
    .el-menu-item {
      .icon-img {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        .pic {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .menu-title,
    .menu-title-left {
      margin-left: 10px;
    }

    .router-link-exact-active {
      width: 100%;
      height: 50px;

      .el-menu-item {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #666666;
        display: flex;
        align-items: center;
        border-left: 5px solid transparent;
      }

      .is-active {
        background-color: rgba(21, 121, 251, 0.1);
        border-left: 5px solid #1579FB;
        .icon-img {
          .pic {
            transform: translateX(-20px);
            -webkit-filter: drop-shadow(20px 0 #1579FB);
            -moz-filter: drop-shadow(20px 0 #1579FB);
            -ms-filter: drop-shadow(20px 0 #1579FB);
            -o-filter: drop-shadow(20px 0 #1579FB);
            filter: drop-shadow(20px 0px #1579FB);
          }
        }
      }
    }

    .router-link-me-active {
      width: 100%;
      height: 40px;

      .el-menu-item {
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #666666;
        display: flex;
        align-items: center;
        border-left: 5px solid transparent;
      }

      .is-active {
        background-color: rgba(21, 121, 251, 0.1);
        border-left: 5px solid #1579FB;

        .icon-img {
          .pic {
            transform: translateX(-20px);
            -webkit-filter: drop-shadow(20px 0 #1579FB);
            -moz-filter: drop-shadow(20px 0 #1579FB);
            -ms-filter: drop-shadow(20px 0 #1579FB);
            -o-filter: drop-shadow(20px 0 #1579FB);
            filter: drop-shadow(20px 0px #1579FB);
          }
        }
      }
    }

    //.router-link-me-active {
    //  .is-active {
    //    background-color: rgba(21, 121, 251, 0.1);
    //    border-left: 5px solid transparent;
    //    .icon-img {
    //      width: 20px;
    //      height: 20px;
    //      position: relative;
    //      overflow: hidden;
    //      .pic {
    //        position: absolute;
    //        top: 0px;
    //        left: 0px;
    //        width: 20px;
    //        height: 20px;
    //        margin-left: -20px;
    //        -webkit-filter: drop-shadow(20px 0 #1579FB);
    //        -moz-filter: drop-shadow(20px 0 #1579FB);
    //        -ms-filter: drop-shadow(20px 0 #1579FB);
    //        -o-filter: drop-shadow(20px 0 #1579FB);
    //        filter: drop-shadow(20px 0px #1579FB);
    //      }
    //    }
    //  }
    //}

    .router-link-exact-active:hover {
      background-color: rgba(21, 121, 251, 0.05);

      .el-menu-item {
        color: #1579FB;

        .icon-img {
          .pic {
            transform: translateX(-20px);
            -webkit-filter: drop-shadow(20px 0 #1579FB);
            -moz-filter: drop-shadow(20px 0 #1579FB);
            -ms-filter: drop-shadow(20px 0 #1579FB);
            -o-filter: drop-shadow(20px 0 #1579FB);
            filter: drop-shadow(20px 0px #1579FB);
          }
        }
      }
    }

    .router-link-me-active:hover {
      background-color: rgba(21, 121, 251, 0.05);

      .el-menu-item {
        color: #1579FB;

        .icon-img {
          .pic {
            transform: translateX(-20px);
            -webkit-filter: drop-shadow(20px 0 #1579FB);
            -moz-filter: drop-shadow(20px 0 #1579FB);
            -ms-filter: drop-shadow(20px 0 #1579FB);
            -o-filter: drop-shadow(20px 0 #1579FB);
            filter: drop-shadow(20px 0px #1579FB);
          }
        }
      }
    }

    .drop-menu {
      width: 100%;
      min-height: 40px;

      :deep(.el-submenu__title) {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #666666;
        display: flex;
        align-items: center;
        border-left: 5px solid transparent;

        .el-submenu__icon-arrow {
          color: #666666;
          font-size: 16px;
          position: initial;
          margin-top: 0px;
          margin-left: 13px;
          font-weight: bold;
        }
      }

      //.drop-menu:hover {
      //  :deep(.el-submenu__title) {
      //    color: #1579FB;
      //    background-color: rgba(21, 121, 251, 0.05);
      //    img {
      //      left: -80px;
      //      -webkit-filter: drop-shadow(80px 0 #1579FB);
      //      filter: drop-shadow(80px 0 #1579FB);
      //    }
      //  }
      //}
    }
  }
}
</style>