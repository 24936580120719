import axios from "axios";
import { getToken } from "@/utils/auth";
import { MessageBox, Message } from 'element-ui';
import { hideLoading } from "@/utils/utils";
import $store from "@/store";
import Router from "@/router";



const instance = axios.create({
  baseURL: '/api',
  timeout: 100000
})

//请求拦截
instance.interceptors.request.use(
  function (config) {
    if (getToken()) {
      config.headers.token = getToken();
    }
    return config
  },
  function (err) {
    if ($store.state.showLoading) {
      hideLoading()
    }
    return Promise.reject(err);
  }
)

//响应拦截
instance.interceptors.response.use(
  function (response) {
    if ($store.state.showLoading) {
      hideLoading()
    }
    let res = response.data;
    if(res.code) {
      if(res.code === 200) {
        return res;
      }else if(res.code === 401 ) {
        Router.push({path:'/login'})
      }else if (res.code === 403) {
        if(Router.currentRoute.path == "/login") {
          Message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          })
          return;
        }else {
          Router.push({path:'/login'});
        }
      }else {
        Message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        })
    }
      return res;
    }
    return res
  },
  function (err) {
    if ($store.state.showLoading) {
      hideLoading()
    }
    return Promise.reject(err);
  }
)

export function get(url,params) {
  return instance.get(url, {
    params
  });
}

export function post(url, data, option = {}) {
  return instance.post(url, data, option);
}

export function del(url, data) {
  return instance.delete(url, data);
}