import { Message } from 'element-ui';
import { Loading } from 'element-ui';
import $store from "@/store";
import dayjs from "dayjs";

//提示框
export const toastMessage = (msg, type = 'error', flag = false, duration = 2000) => {
  Message({
    showClose: flag,
    message: msg,
    type: type,
    duration: duration
  });
}

//loading
export const showLoading = (text='loading') => {
  let intentLoading = Loading.service({
    lock: true,
    text: text,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  $store.commit("changeLoading");
  return intentLoading
}

export const hideLoading = () => {
  showLoading().close();
  $store.commit("disableLoading");
}

//获取当前日期
export const getDay = () => {
  let nowDate = dayjs().format('YYYY年M月DD日');
  return nowDate;
}

//导出zip
export const downloadFiles = (data, name) => {
  const aLink = document.createElement("a");
  let blob = new Blob([data], {type: "application/zip"})
  aLink.href = window.URL.createObjectURL(blob);
  aLink.setAttribute('download', `${name}.zip`)
  document.body.appendChild(aLink)
  aLink.click()
  document.body.removeChild(aLink)
}