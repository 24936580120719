
import {setCookie} from "@/utils/auth";

//缓存一些数据
export const setUserInfo = (val, num) => {
  setCookie("T_token", val.token, num);
  setCookie("T_username", val.username, num);
  setCookie("T_managerName", val.managerName, num);
  setCookie("T_roleId", val.roleId, num);
  setCookie("T_userId", val.userId, num);
  setCookie("T_mobile", val.mobile, num);
}

export const setUserPhone = (name, val) => {
  setCookie(name, val);
}