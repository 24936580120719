import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/index'
import layout from "@/layout";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//默认不需要权限的页面
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
]

//异步路由（需要权限的页面）
export const asyncRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/doc',
    component: () => import('@/views/doc/index'),
    hidden: true
  },
  {
    path: '/share/:id',
    component: () => import('@/views/equipmentShare/index'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [
      {
        name: 'home',
        path: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
        meta: { title: '首页', icon: '' }
      },
      {
        name: 'detail',
        path: 'detail',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/detail.vue'),
        meta: { title: '首页', icon: '', activeMenu: '/infomation/infomation' }
      }
    ]
  },
  {
    path: '/cityInfo',
    component: Layout,
    name: '本市信息',
    meta: { title: '本市信息' },
    noDropdown: true,
    children: [
      {
        name: 'cityInfo',
        path: 'cityInfo',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/cityInfo/index.vue'),
        meta: { title: '本市信息', icon: '' }
      }
    ]
  },
  {
    path: '/countyInfo',
    component: Layout,
    name: '区县信息',
    meta: { title: '区县信息' },
    noDropdown: true,
    children: [
      {
        name: 'countyInfo',
        path: 'countyInfo',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/countyInfo/index.vue'),
        meta: { title: '区县信息', icon: '' }
      }
    ]
  },
  {
    path: '/office',
    component: Layout,
    name: '科室基本信息',
    meta: { title: '科室基本信息' },
    noDropdown: true,
    children: [
      {
        name: 'info',
        path: 'info',
        component: () => import(/* webpackChunkName: "office" */ '@/views/office/info.vue'),
        meta: { title: '科室基本信息', icon: '' }
      },
      {
        name: '相关制度',
        path: 'ruleList/:id',
        component: () => import(/* webpackChunkName: "office" */ '@/views/office/ruleList.vue'),
        meta: { title: '科室基本信息', icon: '',  activeMenu: '/office/info'  }
      },
      {
        name: '操作制度',
        path: 'operationRule/:id',
        component: () => import(/* webpackChunkName: "office" */ '@/views/office/operationRuleList.vue'),
        meta: { title: '科室基本信息', icon: '',  activeMenu: '/office/info'  }
      }
    ]
  },
  {
    path: '/hospital',
    component: Layout,
    name: '医院信息',
    meta: { title: '医院信息' },
    noDropdown: true,
    children: [
      {
        name: 'info',
        path: 'info',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/index.vue'),
        meta: { title: '医院信息', icon: '' }
      },
      {
        name: '个人剂量详情',
        path: 'doseDetail/:id',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/personalDose.vue'),
        meta: { title: '医院信息', icon: '',  activeMenu: '/hospital/info' }
      },
      {
        name: '设备检测详情',
        path: 'deviceDetail/:id',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/deviceDetail.vue'),
        meta: { title: '医院信息', icon: '',  activeMenu: '/hospital/info' }
      },
      {
        name: '医院制度详情',
        path: 'hospitalRule/:id',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/hospitalRule.vue'),
        meta: { title: '医院信息', icon: '',  activeMenu: '/hospital/info' }
      },
      {
        name: '医院设备操作制度详情',
        path: 'hospitalFacility/:id',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/hospitalFacility.vue'),
        meta: { title: '医院信息', icon: '',  activeMenu: '/hospital/info' }
      },
      {
        name: '科室制度详情',
        path: 'hospitalOffice/:id',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/hospital/hospitalOffice.vue'),
        meta: { title: '医院信息', icon: '',  activeMenu: '/hospital/info' }
      },
    ]
  },
  {
    path: '/peopleManagement',
    component: Layout,
    name: '人员管理',
    meta: { title: '人员管理' },
    noDropdown: true, //是否有二级菜单 true/没有二级  false/有二级
    children: [
      {
        path: 'peopleManagement',
        component: () => import(/* webpackChunkName: "peopleManagement" */ '@/views/peopleManagement/index'),
        name: 'peopleManagement',
        meta: { title: '人员管理', icon: '', keepAlive: true }
      },
      {
        path: '/add',
        component: () => import(/* webpackChunkName: "peopleManagement" */ '@/views/peopleManagement/adduser'),
        hidden: true,
        name: '添加',
        meta: { title: '人员管理', icon: '', activeMenu: '/peopleManagement/peopleManagement' }
      },
      {
        path: 'check/:id',
        component: () => import(/* webpackChunkName: "peopleManagement" */ '@/views/peopleManagement/check'),
        hidden: true,
        name: '详情',
        meta: { title: '人员管理', icon: '', activeMenu: '/peopleManagement/peopleManagement' }
      },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "peopleManagement" */ '@/views/peopleManagement/edit'),
        hidden: true,
        name: '编辑',
        meta: { title: '人员管理', icon: '', activeMenu: '/peopleManagement/peopleManagement' }
      }
    ]
  },
  {
    path: '/equipment',
    component: Layout,
    name: '设备管理',
    meta: { title: '设备管理' },
    noDropdown: true,
    children: [
      {
        path: 'equipment',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/index'),
        name: 'equipment',
        meta: { title: '设备管理', icon: '', keepAlive: true  }
      },
      {
        path: '/facility',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/add'),
        hidden: true,
        name: '添加设备',
        meta: { title: '设备管理', icon: '', activeMenu: '/equipment/equipment' }
      },
      {
        path: '/facilityEdit',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/edit'),
        hidden: true,
        name: '编辑设备',
        meta: { title: '设备管理', icon: '', activeMenu: '/equipment/equipment' }
      },
      {
        path: '/see',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/see'),
        hidden: true,
        name: '设备详情',
        meta: { title: '设备详情', icon: '', activeMenu: '/equipment/equipment' }
      },
    ]
  },
  {
    path: '/sealed',
    component: Layout,
    name: '放射源管理',
    meta: { title: '放射源管理' },
    noDropdown: false,
    children: [
      {
        name: '密封管理',
        path: '/sealed',
        component: () => import(/* webpackChunkName: "sourceMnager" */ '@/views/sourceManage/sealed'),
        meta: { title: '密封管理', icon: '', keepAlive: true  }
      },
      {
        name: '新增放射源（密封）',
        path: '/addSealed',
        component: () => import(/* webpackChunkName: "sourceMnager" */ '@/views/sourceManage/addSealed'),
        meta: { title: '新增放射源（密封）', icon: '' },
        hidden: true
      },
      {
        name: '出库记录',
        path: '/outbound/:sourcesSealId',
        component: () => import(/* webpackChunkName: "sourceMnager" */'@/views/sourceManage/outbound'),
        meta: { title: '出库记录', icon: '', activeMenu: '/sealed' },
        hidden: true
      },
      {
        name: '非密封管理',
        path: '/unsealed',
        component: () => import(/* webpackChunkName: "sourceMnager" */ '@/views/sourceManage/unsealed.vue'),
        meta: { title: '非密封管理', icon: '', keepAlive: true  }
      },
      {
        name: '新增放射源（非密封）',
        path: '/addUnsealed',
        component: () => import(/* webpackChunkName: "sourceMnager" */ '@/views/sourceManage/addUnsealed'),
        meta: { title: '新增放射源（非密封）', icon: '' },
        hidden: true
      },
      {
        name: '出库记录',
        path: '/unsealedOutbound/:sourcesSealId',
        component: () => import(/* webpackChunkName: "sourceMnager" */'@/views/sourceManage/unsealedOutbound'),
        meta: { title: '出库记录', icon: '', activeMenu: '/unsealed' },
        hidden: true
      },
    ]
  },
  {
    path: '/sourceManage',
    component: Layout,
    name: '放射源管理',
    meta: { title: '放射源管理' },
    noDropdown: true,
    children: [
      {
        name: '放射源管理',
        path: '/sourceManage',
        component: () => import(/* webpackChunkName: "hospital" */ '@/views/sourceManage/sourceManage'),
        meta: { title: '放射源管理', icon: '', keepAlive: true  }
      }
    ]
  },
  {
    path: '/qualityControl',
    component: Layout,
    name: '质量控制设备',
    meta: { title: '质量控制设备' },
    noDropdown: true,
    children: [
      {
        path: 'qualityControl',
        component: () => import(/* webpackChunkName: "qualityControl" */ '@/views/qualityControl/qualityControl'),
        name: '质量控制设备',
        meta: { title: '质量控制设备', icon: '', keepAlive: true  }
      }
    ]
  },
  {
    path: '/infomation',
    component: Layout,
    name: '资讯信息',
    meta: { title: '资讯信息' },
    noDropdown: false,
    children: [
      {
        name: '知识库',
        path: '/infomation',
        component: () => import(/* webpackChunkName: "infomation" */ '@/views/infomation/infomation.vue'),
        meta: { title: '资讯信息', icon: '' }
      },
      {
        name: '通告',
        path: '/notification',
        component: () => import(/* webpackChunkName: "sourceMnager" */'@/views/infomation/notification.vue'),
        meta: { title: '资讯信息', icon: '', activeMenu: '/notification' },
      },
      {
        name: '制度',
        path: '/rule',
        component: () => import(/* webpackChunkName: "sourceMnager" */'@/views/infomation/rule.vue'),
        meta: { title: '资讯信息', icon: '' },
      },
      {
        name: '添加通告',
        path: '/addNotifi',
        component: () => import(/* webpackChunkName: "infomation" */ '@/views/infomation/addNotifi.vue'),
        meta: { title: '添加通告', icon: '', activeMenu: '/notification' },
        hidden: true
      }
    ]
  },
  {
    path: '/system',
    component: layout,
    name: 'system',
    meta: { title: '系统管理' },
    noDropdown: true,
    children: [
      {
        name: 'management',
        path: 'management',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/index.vue'),
        meta: { title: '系统管理', icon: '', keepAlive: true  }
      },
      {
        name: '医院详情',
        path: 'detail/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/detail.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '个人剂量详情',
        path: 'hospitalDose/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/hospitalDose.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '设备检测详情',
        path: 'hospitalDevice/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/hospitalDevice.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '医院制度',
        path: 'hospitalRule/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/hospitalRule.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '设备制度',
        path: 'hospitalFacility/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/hospitalFacility.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '科室制度制度',
        path: 'hospitalOffice/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/county/hospitalOffice.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '科室详情',
        path: 'office/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/hospital/officeInfo.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '相关制度',
        path: 'officeRuleList/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/hospital/officeRuleList.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
      {
        name: '操作制度',
        path: 'officeOperationRule/:id',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/content/hospital/officeOperationRuleList.vue'),
        meta: { title: '系统管理', icon: '', activeMenu: '/system/management' }
      },
    ]
  },
  {
    path: '/help',
    component: layout,
    name: 'help',
    meta: { title: '帮助支持' },
    noDropdown: true,
    children: [
      {
        name: 'support',
        path: 'support',
        component: () => import(/* webpackChunkName: "support" */ '@/views/support/index.vue'),
        meta: { title: '帮助支持', icon: '' }
      }
    ]
  },
  {
    path: '/demo',
    component: Layout,
    name: 'demo',
    meta: { title: 'demo' },
    hidden: true,
    children: [
      {
        name: 'index',
        path: 'index',
        component: () => import(/* webpackChunkName: "demo" */ '@/views/demo/index.vue'),
        meta: { title: 'demo', icon: '' }
      }
    ]
  },
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: asyncRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
