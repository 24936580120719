<template>
  <div class="app-header">
    <!-- 左侧系统名 -->
    <div class="header-left" @click="goHome">
      <img src="../../assets/logo.png" alt="" width="40px" height="40px">
      <div style="padding-left: 10px;">九江市放射卫生管理系统</div>
    </div>
    <div class="organization-view" v-if="cacheOrganization">
      <span class="organization-title">{{ cacheOrganization.type == 1 ? '选择医院：' : '选择科室：' }}</span>
      <span class="organization-name" @click="selectOrganization">{{ cacheOrganization.name }}</span>
      <span class="organization-delete" @click="deleteCacheOrganization">x</span>
    </div>
    <!-- 右侧功能模块 -->
    <div class="header-right">
      <div v-if="haveMessage" class="header-message" @click="messageClick">
        <img class="message-img" src="../../assets/message.gif" alt="">
      </div>
      <div class="header-name">
        <el-dropdown trigger="click">
          <div class="info pointer">
            <span class="name">您好，{{ name }}</span>
            <i class="el-icon-caret-bottom b-icon"></i>
          </div>
          <el-dropdown-menu :append-to-body="false" slot="dropdown">
            <el-dropdown-item @click.native="changeUserInfo">个人资料</el-dropdown-item>
            <el-dropdown-item divided @click.native="updatePassword">修改密码</el-dropdown-item>
            <el-dropdown-item divided @click.native="exit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="390px" :modal-append-to-body="false" :show-close="false">
      <template slot="title">
        <dialog-header name="修改密码" height="46" />
      </template>
      <update-password :key="key" @handleClose="updatePassword" />
    </el-dialog>
    <el-dialog :visible.sync="userInfoShow" width="390px" :modal-append-to-body="false" :show-close="false">
      <template slot="title">
        <dialog-header name="个人资料" height="46" />
      </template>
      <user-info :key="userKey" @handleClose="changeUserInfo" />
    </el-dialog>
    <el-dialog :visible.sync="messageShow" width="600px" :modal-append-to-body="false" :show-close="false">
      <template slot="title">
        <dialog-header name="消息提醒" height="46" :show-close="true" @headerClose="headerClose" />
      </template>
      <div class="message-content">
        <message :key="messageKey" />
      </div>
    </el-dialog>
    <!-- changeOrganization -->
    <el-dialog :visible.sync="changeOrganization" width="600px" :modal-append-to-body="false" :show-close="false"
      @close="close">
      <dialog-header v-if="cacheOrganization && cacheOrganization.type == 1" name="切换医院" height="46" />
      <dialog-header v-else name="切换科室" height="46" />
      <div class="select-content">
        <div v-if="Number(role) < 5" class="filter-view">
          <div class="filter-item" v-if="Number(role) < 4">
            <div class="filter-title">区县</div>
            <el-select class="filter-select" v-model="changeInfo.county" clearable placeholder="选择区县" filterable
              @change="changeCounty">
              <el-option v-for="i in countyData" :key="i.id" :label="i.name" :value="i.id" />
            </el-select>
          </div>
          <div class="filter-item" v-if="Number(role) < 5">
            <div class=" filter-title">医院</div>
            <el-select class="filter-select" v-model="changeInfo.hospital" clearable placeholder="选择医院" filterable>
              <el-option v-for="i in hospitalData" :key="i.hospitalId" :label="i.hospitalName" :value="i.hospitalId" />
            </el-select>
          </div>
        </div>
        <div class="filter-list" v-if="Number(role) == 5">
          <div v-for="(item, index) in officeData" :key="index">
            <div v-if="index % 2 == 0" class="list-row">
              <div class="list-content">
                <div class="list-title" @click="selectOffice(item)">{{ item.officeeName }}</div>
              </div>
              <div class="list-content">
                <div class="list-title" @click="selectOffice(officeData[index + 1])" v-if="(index + 1) < officeData.length">{{
                    officeData[index + 1].officeeName
                }}</div>
              </div>

            </div>
          </div>
        </div>
        <div class="filter-list" v-else>
          <div v-for="(item, index) in filterHospital()" :key="index">
            <div v-if="index % 2 == 0" class="list-row">
              <div class="list-content">
                <div class="list-title" @click="selectHospital(item)">{{ item.hospitalName }}</div>
              </div>
              <div class="list-content">
                <div class="list-title" @click="selectHospital(filterHospital()[index + 1])"
                  v-if="(index + 1) < filterHospital().length">
                  {{
                      filterHospital()[index + 1].hospitalName
                  }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="dialogFooter" slot="footer">
        <el-button class="infoButton" type="primary" @click="changeOrganization = false">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import * as api from "@/api/index";
import { getName, getRole } from "@/utils/auth";
import { removeToken } from "@/utils/auth";
import dialogHeader from "@/components/dialogHeader";
import updatePassword from "@/views/login/changePassword";
import userInfo from "@/views/login/userInfo";
import message from "@/views/message";
import { getOrganizationInfo, removeOrganizationInfo, setOrganizationInfo } from '@/utils/organizationInfo';
export default {
  name: "app-header",
  components: {
    dialogHeader,
    updatePassword,
    userInfo,
    message
  },
  data() {
    return {
      dialogVisible: false,
      userInfoShow: false,
      messageShow: false,
      haveMessage: false,
      changeOrganization: false,
      key: '0',
      userKey: '0',
      messageKey: '',
      name: getName(),
      role: getRole(),
      // 缓存的机构信息
      cacheOrganization: getOrganizationInfo(),
      // 区县数据
      countyData: [],
      // 医院数据
      hospitalData: [],
      // 科室数据
      officeData: [],
      // 搜索数据
      changeInfo: {
        county: '',
        hospital: '',
        office: '',
      }
    }
  },
  methods: {
    //更改密码弹框
    updatePassword() {
      this.dialogVisible = !this.dialogVisible;
      if (this.dialogVisible === true) {
        this.key = new Date() + '';
      }
    },
    //退出登录提示框
    exit() {
      this.$confirm('确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout();
      }).catch(() => {

      })
    },
    //退出登录请求
    logout() {
      let params = {};
      api.login.logout(params).then((res) => {
        let result = res;
        if (result.code === 200) {
          removeToken();
          removeOrganizationInfo();
          this.$router.push({
            path: '/login'
          })
        }
      })
    },
    // 修改个人信息弹框
    changeUserInfo() {
      this.userInfoShow = !this.userInfoShow;
      if (this.userInfoShow === true) {
        this.userKey = new Date() + '';
      }
    },
    // 消息提醒弹框
    messageClick() {
      this.messageKey = new Date() + '';
      this.headerClose();
    },
    //关闭消息弹框
    headerClose() {
      this.messageShow = !this.messageShow;
    },
    //查询是否有未读消息
    getHaveMessage() {
      api.notice.haveMessage({}).then((res) => {
        if (res.code === 200) {
          if (res.data > 0) {
            this.haveMessage = true;
          }
        }
      })
    },
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    // 选择医院
    selectOrganization() {
      this.changeOrganization = true
      this.getOption()
    },
    // 清除选择的医院
    deleteCacheOrganization() {
      removeOrganizationInfo()
      this.$root.eventHub.$emit('organizationInfoUpdate')
    },
    // 切换科室
    selectOffice(val) {
      let office = {
        type: 2,
        id: val.officeeId,
        name: val.officeeName,
        fatherId: val.belongHospital
      }
      this.changeOrganization = false
      setOrganizationInfo(JSON.stringify(office))
      window.location.reload();
      this.$root.eventHub.$emit('organizationInfoUpdate')
    },
    selectHospital(val) {
      let office = {
        type: 1,
        id: val.hospitalId,
        name: val.hospitalName
      }
      this.changeOrganization = false
      setOrganizationInfo(JSON.stringify(office))
      window.location.reload();
      this.$root.eventHub.$emit('organizationInfoUpdate')
    },
    // 弹窗关闭
    close() {
      this.changeInfo = {
        county: '',
        hospital: '',
        office: '',
      }
    },
    // 区县发生了变化
    changeCounty(val) {
      // 区县以下需要再选择区县和医院
      this.queryOrg('4', null, null, null, val, null, 2)
      // 区县以下需要再选择区县和医院
      this.queryOrg('3', null, null, null, val, null, 3)
    },
    // 获取筛选的医院
    filterHospital() {
      if (this.changeInfo.hospital == '') {
        return this.hospitalData
      } else {
        return this.hospitalData.filter((item, _) => {
          return item.hospitalId == this.changeInfo.hospital
        })
      }
    },
    // 获取当前筛选信息
    getOption() {
      if (Number(this.role) == 5) {
        // 医院只能获取科室
        this.queryOrg('5', null, null, null, null, null, 1)
      }
      if (Number(this.role) < 5) {
        // 区县以下需要再选择区县和医院
        this.queryOrg('4', null, null, null, null, null, 2)
      }
      if (Number(this.role) < 4) {
        // 区县以下需要再选择区县和医院
        this.queryOrg('3', null, null, null, null, null, 3)
      }
    },
    // 获取当前登录人省，市，区县，医院，科室数据
    queryOrg(queryType, parentId, belongProvince, belongCity, belongPrefecture, belongHospital, resType) {
      let queryParams = {
        queryType: queryType
      }
      if (parentId) {
        queryParams.parentId = parentId
      }
      if (belongProvince) {
        queryParams.belongProvince = belongProvince
      }
      if (belongCity) {
        queryParams.belongCity = belongCity
      }
      if (belongPrefecture) {
        queryParams.belongPrefecture = belongPrefecture
      }
      if (belongHospital) {
        queryParams.belongHospital = belongHospital
      }
      api.publich.getSelectOrg(queryParams).then((res) => {
        if (res.code == 200) {
          if (resType == 1) {
            this.officeData = res.data
          } else if (resType == 2) {
            this.hospitalData = res.data
          } else if (resType == 3) {
            this.countyData = res.data
          }
        }
      })
    }
  },
  created() {
    this.getHaveMessage();
    this.$root.eventHub.$on('organizationInfoUpdate', () => {
      this.cacheOrganization = getOrganizationInfo()
    })
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: #1579FB;
  box-shadow: 0px 4px 10px 3px rgba(136, 130, 116, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 41px 0px 36px;
  z-index: 1;

  .header-left {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FEFEFE;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .organization-view {
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    .organization-title {}

    .organization-name {
      font-size: 16px;
      cursor: pointer;
      border-bottom: 1px solid white;
    }

    .organization-delete {
      cursor: pointer;
      font-weight: 400;
      padding-left: 10px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-message {
      display: flex;
      align-items: center;
      cursor: pointer;

      .message-img {
        display: flex;
        align-items: center;
        color: #FEFEFE;
        width: 18px;
        height: 21px;
      }

    }

    .header-name {
      margin-left: 18px;
      color: #FEFEFE;

      .info {
        display: flex;
        align-items: center;

        .name {
          margin-left: 5px;
          margin-right: 5px;
          font-size: 16px;
          color: #FEFEFE;
        }

        .b-icon {
          width: 14px;
          height: 8px;
          display: flex;
          align-items: center;
          color: #FEFEFE;
        }
      }

      .el-dropdown-menu {
        width: 130px;
        border: 1px solid #DCDCDC;
        box-shadow: 0px 5px 10px 0px rgba(137, 137, 137, 0.1);
        border-radius: 4px;
        padding: 0px 12px;

        .el-dropdown-menu__item:hover {
          background-color: transparent;
        }

        .el-dropdown-menu__item--divided {
          margin-top: 0px;
        }

        .el-dropdown-menu__item--divided:before {
          height: 1px;
          background: #DCDCDC;
        }
      }
    }
  }

  :deep(.el-dialog__footer) {
    text-align: center;
  }

  .message-content {
    min-height: 100px;
  }

  .select-content {
    padding: 30px 30px 20px 30px;

    .filter-view {
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      .filter-item {
        color: #333333;
        display: flex;
        flex-direction: row;
        height: 44px;

        .filter-title {
          font-size: 14px;
          padding-right: 20px;
          line-height: 44px;
        }

        .filter-select {
          width: 200px;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0 !important;
    }

    ::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }

    .filter-list {
      max-height: 200px;
      overflow-y: auto;

      .list-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      .list-content {
        width: 50%;
      }

      .list-title {
        padding-top: 20px;
        color: #333333;
        cursor: pointer;
        color: #1579FB;
        border-bottom: 1px solid #1579FB;
        width: fit-content;
      }

    }
  }

  .dialogFooter {
    padding-bottom: 10px;

    .infoButton {
      width: 200px;
    }
  }
}
</style>