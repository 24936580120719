
import Cookies from "js-cookie";

//设置token
export const setCookie = (name, value, date) => {
  Cookies.set(name, value, { expires: date });
}

//获取token
export const getToken = () => {
  return Cookies.get("T_token");
}

//获取角色 角色ID 1、超级管理员2、省级管理员3、市级管理员4、县级管理员5、医院管理员6、科室管理员
export const getRole = () => {
  return Cookies.get("T_roleId");
}

//获取账号
export const getAccount = () => {
  return Cookies.get("T_username");
}

//获取管理员名称
export const getName = () => {
  return Cookies.get("T_managerName");
}

//获取用户ID
export const getUserId = () => {
  return Cookies.get("T_userId");
}

//获取用户手机号
export const getUserMobile = () => {
  return Cookies.get("T_mobile");
}

//移除缓存的值
export const removeToken = () => {
  Cookies.remove("T_roleId");
  Cookies.remove("T_username");
  Cookies.remove("T_token");
  Cookies.remove("T_managerName");
  Cookies.remove("T_userId");
  Cookies.remove("T_mobile");
}
