const dicData = {
  // 单位所属级别常量
  unitLevel: [
    { label: '省直', value: 1 },
    { label: '市直', value: 2 },
    { label: '区县', value: 3 }
  ],
  // 单位性质
  unitNature: [
    { label: '公立', value: 1 },
    { label: '民营', value: 2 },
    { label: '合资', value: 3 }
  ],
  // 医院等级
  hospitalLevel: [
    { label: '三甲', value: 1 },
    { label: '三乙', value: 2 },
    { label: '二甲', value: 3 },
    { label: '二乙', value: 4 },
    { label: '二乙以下', value: 5 },
    { label: '无等级', value: 6 }
  ],
  //性别
  sex: [
    { value: "1", label: "男", },
    { value: "0", label: "女", },
  ],
  //职务
  job: [
    { value: '院长', label: '院长' },
    { value: '副院长', label: '副院长' },
    { value: '负责人', label: '负责人' },
    { value: '科主任/科长', label: '科主任/科长' },
    { value: '科副主任/副科长', label: '科副主任/副科长' },
    { value: '护士长', label: '护士长' },
    { value: '无', label: '无' },
  ],
  //职称
  professional: [
    { value: '主任医师', label: '主任医师' },
    { value: '副主任医师', label: '副主任医师' },
    { value: '主治医师', label: '主治医师' },
    { value: '医师', label: '医师' },
    { value: '主任技师', label: '主任技师' },
    { value: '副主任技师', label: '副主任技师' },
    { value: '主管技师', label: '主管技师' },
    { value: '技师', label: '技师' },
    { value: '技士', label: '技士' },
    { value: '主任护师', label: '主任护师' },
    { value: '副主任护师', label: '副主任护师' },
    { value: '主管护师', label: '主管护师' },
    { value: '护师', label: '护师' },
    { value: '护士', label: '护士' },
    { value: '高级工程师', label: '高级工程师' },
    { value: '工程师', label: '工程师' },
    { value: '主力工程师', label: '主力工程师' },
    { value: '无', label: '无' },
  ],
  //学历
  education: [
    { value: '中专/高中', label: '中专/高中' },
    { value: '专科', label: '专科' },
    { value: '本科', label: '本科' },
    { value: '硕士研究生', label: '硕士研究生' },
    { value: '博士研究生', label: '博士研究生' },
    { value: '其他', label: '其他' },
  ],
  //设备
  equipment: [
    { value: '正常', label: '正常' },
    { value: '遗失', label: '遗失' },
    { value: '损坏', label: '损坏' },
  ],
  //检测结果
  detection: [
    { value: '正常', label: '正常' },
    { value: '异常', label: '异常' },
  ],
  //体检结论
  health: [
    { value: '可继续从事放射工作', label: '可继续从事放射工作' },
    { value: '需暂时停止放射工作', label: '需暂时停止放射工作' },
    { value: '建议调离放射工作岗位', label: '建议调离放射工作岗位' },
  ],
  //放射防护状态
  stateList: [
    { value: '0', label: '禁用' },
    { value: '1', label: '启用' },
    { value: '2', label: '报废' },
  ],
  //设备类型
  facilityList: [
    {
      "id": "1",
      "title": "放射诊断设备",
      "children": [
        {
          "id": "11",
          "title": "传统屏片X射线摄影设备(普通X射线摄影机)"
        },
        {
          "id": "12",
          "title": "双球管X射线设备(双管头X射线机)"
        },
        {
          "id": "13",
          "title": "X射线计算机体层摄影装置(CT)"
        },
        {
          "id": "14",
          "title": "计算机X射线摄影设备(CR)#"
        },
        {
          "id": "15",
          "title": "数字X射线摄影设备(DR)"
        },
        {
          "id": "16",
          "title": "移动式C形臂X射线机(C臂机)"
        },
        {
          "id": "17",
          "title": "乳腺计算机X射线摄影设备(乳腺CR)"
        },
        {
          "id": "18",
          "title": "乳腺数字X射线摄影设备(乳腺DR)"
        },
        {
          "id": "19",
          "title": "乳腺屏片X射线摄影设备(乳腺屏片机)"
        },
        {
          "id": "20",
          "title": "乳腺数字体层合成摄影设备(乳腺CT)"
        },
        {
          "id": "21",
          "title": "数字医用诊断X射线透视摄影系统(胃肠机)"
        },
        {
          "id": "22",
          "title": "医用碎石机"
        },
        {
          "id": "23",
          "title": "透视机(荧光屏型)"
        },
        {
          "id": "24",
          "title": "透视机(影像增强器型)"
        },
        {
          "id": "25",
          "title": "透视机(平板探测器型)"
        },
        {
          "id": "26",
          "title": "X射线骨密度检测仪(骨密度仪)"
        },
        {
          "id": "27",
          "title": "牙科口内X射线设备(牙片机)"
        },
        {
          "id": "28",
          "title": "牙科口外X射线设备(口腔全景机)"
        },
        {
          "id": "29",
          "title": "口腔颌面锥形束计算机断层扫描设备(口腔CT)"
        },
        {
          "id": "30",
          "title": "移动式摄影X射线机(移动X射线机)"
        },
        {
          "id": "31",
          "title": "移动式数字X射线摄影机(移动DR)"
        },
        {
          "id": "32",
          "title": "便携式X射线机"
        },
        {
          "id": "33",
          "title": "数字化厢式X射线机(车载DR)"
        },
        {
          "id": "34",
          "title": "数字化厢式乳腺X射线机(车载乳腺DR)"
        },
        {
          "id": "35",
          "title": "厢式X射线计算机体层摄影装置(车载CT)"
        },
        {
          "id": "36",
          "title": "车载透视机"
        },
        {
          "id": "37",
          "title": "移动式X射线计算机体层摄影装置(移动CT)"
        },
        {
          "id": "38",
          "title": "动态数字摄影透视设备(动态DR设备)"
        },
      ]
    },
    {
      "id": "2",
      "title": "放射介入设备",
      "children": [
        {
          "id": "39",
          "title": "数字减影血管造影X射线设备(DSA)"
        },
        {
          "id": "40",
          "title": "ERCP专用X射线成像系统"
        },
        {
          "id": "41",
          "title": "介入C臂机"
        },
      ]
    },
    {
      "id": "3",
      "title": "放射治疗设备",
      "children": [
        {
          "id": "111",
          "title": "医用电子直线加速器(LA)"
        },
        {
          "id": "112",
          "title": "螺旋断层治疗装置(TOMO)"
        },
        {
          "id": "113",
          "title": "X射线立体定向放射治疗系统(X刀)"
        },
        {
          "id": "114",
          "title": "头部γ射线立体定向放射治疗系统(头部伽玛刀)"
        },
        {
          "id": "115",
          "title": "体部γ射线立体定向放射治疗系统(体部伽玛刀)"
        },
        {
          "id": "116",
          "title": "陀螺旋转式钴0立体定向放射治疗系统(陀螺刀)"
        },
        {
          "id": "117",
          "title": "放射外科手术系统(术中放疗机)"
        },
        {
          "id": "118",
          "title": "移动式电子加速器"
        },
        {
          "id": "119",
          "title": "磁共振加速器"
        },
        {
          "id": "120",
          "title": "后装治疗机(Ir-192源)"
        },
        {
          "id": "121",
          "title": "后装治疗机(Co-60源)"
        },
        {
          "id": "122",
          "title": "Cf-252中子后装放射治疗设备"
        },
        {
          "id": "123",
          "title": "医用X射线治疗机"
        },
        {
          "id": "124",
          "title": "Co-60远距离治疗机"
        },
        {
          "id": "125",
          "title": "机械臂放射治疗装置(射波刀)"
        },
        {
          "id": "126",
          "title": "质子放射治疗系统"
        },
        {
          "id": "127",
          "title": "重离子放射治疗系统"
        },
        {
          "id": "128",
          "title": "常规X线模拟定位机"
        },
        {
          "id": "129",
          "title": "CT模拟定位机"
        },
      ]
    },
    {
      "id": "4",
      "title": "核医学设备",
      "children": [
        {
          "id": "42",
          "title": "单光子发射断层成像设备(SPECT)"
        },
        {
          "id": "43",
          "title": "单光子发射断层成像设备带CT(SPECT-CT)"
        },
        {
          "id": "44",
          "title": "伽玛照相机"
        },
        {
          "id": "45",
          "title": "正电子发射断层成像装置带CT(PET-CT)"
        },
        {
          "id": "46",
          "title": "正电子发射断层成像装置(PET)"
        },
        {
          "id": "47",
          "title": "正电子发射磁共振成像系统(PET-MR)"
        },
        {
          "id": "48",
          "title": "DS(国产PET)"
        },
        {
          "id": "49",
          "title": "医用回旋加速器"
        }
      ]
    },
  ],

  //设备类型
  equipmentList: [
    {
      "id": "1",
      "label": "放射诊断设备",
      "value": "放射诊断设备",
      "children": [
        {
          "id": "11",
          "label": "传统屏片X射线摄影设备(普通X射线摄影机)",
          "value": "传统屏片X射线摄影设备(普通X射线摄影机)"
        },
        {
          "id": "12",
          "label": "双球管X射线设备(双管头X射线机)",
          "value": "双球管X射线设备(双管头X射线机)",
        },
        {
          "id": "13",
          "label": "X射线计算机体层摄影装置(CT)",
          "value": "X射线计算机体层摄影装置(CT)",
        },
        {
          "id": "14",
          "label": "计算机X射线摄影设备(CR)#",
          "value": "计算机X射线摄影设备(CR)#",
        },
        {
          "id": "15",
          "label": "数字X射线摄影设备(DR)",
          "value": "数字X射线摄影设备(DR)",
        },
        {
          "id": "16",
          "label": "移动式C形臂X射线机(C臂机)",
          "value": "移动式C形臂X射线机(C臂机)",
        },
        {
          "id": "17",
          "label": "乳腺计算机X射线摄影设备(乳腺CR)",
          "value": "乳腺计算机X射线摄影设备(乳腺CR)",
        },
        {
          "id": "18",
          "label": "乳腺数字X射线摄影设备(乳腺DR)",
          "value": "乳腺数字X射线摄影设备(乳腺DR)",
        },
        {
          "id": "19",
          "label": "乳腺屏片X射线摄影设备(乳腺屏片机)",
          "value": "乳腺屏片X射线摄影设备(乳腺屏片机)",
        },
        {
          "id": "20",
          "label": "乳腺数字体层合成摄影设备(乳腺CT)",
          "value": "乳腺数字体层合成摄影设备(乳腺CT)",
        },
        {
          "id": "21",
          "label": "数字医用诊断X射线透视摄影系统(胃肠机)",
          "value": "数字医用诊断X射线透视摄影系统(胃肠机)",
        },
        {
          "id": "22",
          "label": "医用碎石机",
          "value": "医用碎石机",
        },
        {
          "id": "23",
          "label": "透视机(荧光屏型)",
          "value": "透视机(荧光屏型)",
        },
        {
          "id": "24",
          "label": "透视机(影像增强器型)",
          "value": "透视机(影像增强器型)",
        },
        {
          "id": "25",
          "label": "透视机(平板探测器型)",
          "value": "透视机(平板探测器型)",
        },
        {
          "id": "26",
          "label": "X射线骨密度检测仪(骨密度仪)",
          "value": "X射线骨密度检测仪(骨密度仪)",
        },
        {
          "id": "27",
          "label": "牙科口内X射线设备(牙片机)",
          "value": "牙科口内X射线设备(牙片机)",
        },
        {
          "id": "28",
          "label": "牙科口外X射线设备(口腔全景机)",
          "value": "牙科口外X射线设备(口腔全景机)",
        },
        {
          "id": "29",
          "label": "口腔颌面锥形束计算机断层扫描设备(口腔CT)",
          "value": "口腔颌面锥形束计算机断层扫描设备(口腔CT)",
        },
        {
          "id": "30",
          "label": "移动式摄影X射线机(移动X射线机)",
          "value": "移动式摄影X射线机(移动X射线机)",
        },
        {
          "id": "31",
          "label": "移动式数字X射线摄影机(移动DR)",
          "value": "移动式数字X射线摄影机(移动DR)",
        },
        {
          "id": "32",
          "label": "便携式X射线机",
          "value": "便携式X射线机",
        },
        {
          "id": "33",
          "label": "数字化厢式X射线机(车载DR)",
          "value": "数字化厢式X射线机(车载DR)",
        },
        {
          "id": "34",
          "label": "数字化厢式乳腺X射线机(车载乳腺DR)",
          "value": "数字化厢式乳腺X射线机(车载乳腺DR)",
        },
        {
          "id": "35",
          "label": "厢式X射线计算机体层摄影装置(车载CT)",
          "value": "厢式X射线计算机体层摄影装置(车载CT)",
        },
        {
          "id": "36",
          "label": "车载透视机",
          "value": "车载透视机",
        },
        {
          "id": "37",
          "label": "移动式X射线计算机体层摄影装置(移动CT)",
          "value": "移动式X射线计算机体层摄影装置(移动CT)",
        },
        {
          "id": "38",
          "label": "动态数字摄影透视设备(动态DR设备)",
          "value": "动态数字摄影透视设备(动态DR设备)",
        },
      ]
    },
    {
      "id": "2",
      "label": "放射介入设备",
      "value": "放射介入设备",
      "children": [
        {
          "id": "39",
          "label": "数字减影血管造影X射线设备(DSA)",
          "value": "数字减影血管造影X射线设备(DSA)",
        },
        {
          "id": "40",
          "label": "ERCP专用X射线成像系统",
          "value": "ERCP专用X射线成像系统",
        },
        {
          "id": "41",
          "label": "介入C臂机",
          "value": "介入C臂机",
        },
      ]
    },
    {
      "id": "3",
      "label": "放射治疗设备",
      "value": "放射治疗设备",
      "children": [
        {
          "id": "111",
          "label": "医用电子直线加速器(LA)",
          "value": "医用电子直线加速器(LA)",
        },
        {
          "id": "112",
          "label": "螺旋断层治疗装置(TOMO)",
          "value": "螺旋断层治疗装置(TOMO)",
        },
        {
          "id": "113",
          "label": "X射线立体定向放射治疗系统(X刀)",
          "value": "X射线立体定向放射治疗系统(X刀)",
        },
        {
          "id": "114",
          "label": "头部γ射线立体定向放射治疗系统(头部伽玛刀)",
          "value": "头部γ射线立体定向放射治疗系统(头部伽玛刀)",
        },
        {
          "id": "115",
          "label": "体部γ射线立体定向放射治疗系统(体部伽玛刀)",
          "value": "体部γ射线立体定向放射治疗系统(体部伽玛刀)",
        },
        {
          "id": "116",
          "label": "陀螺旋转式钴0立体定向放射治疗系统(陀螺刀)",
          "value": "陀螺旋转式钴0立体定向放射治疗系统(陀螺刀)",
        },
        {
          "id": "117",
          "label": "放射外科手术系统(术中放疗机)",
          "value": "放射外科手术系统(术中放疗机)",
        },
        {
          "id": "118",
          "label": "移动式电子加速器",
          "value": "移动式电子加速器",
        },
        {
          "id": "119",
          "label": "磁共振加速器",
          "value": "磁共振加速器",
        },
        {
          "id": "120",
          "label": "后装治疗机(Ir-192源)",
          "value": "后装治疗机(Ir-192源)",
        },
        {
          "id": "121",
          "label": "后装治疗机(Co-60源)",
          "value": "后装治疗机(Co-60源)",
        },
        {
          "id": "122",
          "label": "Cf-252中子后装放射治疗设备",
          "value": "Cf-252中子后装放射治疗设备",
        },
        {
          "id": "123",
          "label": "医用X射线治疗机",
          "value": "医用X射线治疗机",
        },
        {
          "id": "124",
          "label": "Co-60远距离治疗机",
          "value": "Co-60远距离治疗机",
        },
        {
          "id": "125",
          "label": "机械臂放射治疗装置(射波刀)",
          "value": "机械臂放射治疗装置(射波刀)",
        },
        {
          "id": "126",
          "label": "质子放射治疗系统",
          "value": "质子放射治疗系统",
        },
        {
          "id": "127",
          "label": "重离子放射治疗系统",
          "value": "重离子放射治疗系统",
        },
        {
          "id": "128",
          "label": "常规X线模拟定位机",
          "value": "常规X线模拟定位机",
        },
        {
          "id": "129",
          "label": "CT模拟定位机",
          "value": "CT模拟定位机",
        },
      ]
    },
    {
      "id": "4",
      "label": "核医学设备",
      "value": "核医学设备",
      "children": [
        {
          "id": "42",
          "label": "单光子发射断层成像设备(SPECT)",
          "value": "单光子发射断层成像设备(SPECT)",
        },
        {
          "id": "43",
          "label": "单光子发射断层成像设备带CT(SPECT-CT)",
          "value": "单光子发射断层成像设备带CT(SPECT-CT)",
        },
        {
          "id": "44",
          "label": "伽玛照相机",
          "value": "伽玛照相机",
        },
        {
          "id": "45",
          "label": "正电子发射断层成像装置带CT(PET-CT)",
          "value": "正电子发射断层成像装置带CT(PET-CT)",
        },
        {
          "id": "46",
          "label": "正电子发射断层成像装置(PET)",
          "value": "正电子发射断层成像装置(PET)",
        },
        {
          "id": "47",
          "label": "正电子发射磁共振成像系统(PET-MR)",
          "value": "正电子发射磁共振成像系统(PET-MR)",
        },
        {
          "id": "48",
          "label": "DS(国产PET)",
          "value": "DS(国产PET)",
        },
        {
          "id": "49",
          "label": "医用回旋加速器",
          "value": "医用回旋加速器",
        }
      ]
    },
  ],
  // icon配置
  iconConfig: {
    equipment: 'device',
    help: 'help',
    hospital: 'hospital',
    infomation: 'information',
    office: 'office',
    peopleManagement: 'person',
    qualityControl: 'qualityControl',
    sealed: 'source',
    system: 'system',
    cityInfo: '',
    countyInfo: '',
  }
}
export default dicData;