//缓存设备ID
export const setEquipmentId = (key, val) => {
  localStorage.setItem(key, val);
}

//获取设备ID
export const getEquipmentId = (key) => {
  return localStorage.getItem(key);
}

//删除设备ID
export const removeEquipmentId = (key) => {
  localStorage.removeItem(key);
}