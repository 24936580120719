//登录接口
import * as request from '@/utils/request';
import * as api from '@/config/api'

//登录
export const login = (data) => {
  return request.post(api.login.login, data);
}

//注册
export const register = (data) => {
  return request.post(api.login.register, data);
}

//退出登录
export const logout = (data) => {
  return request.post(api.login.logout, data);
}

//登录发送验证码
export const logSms = (data) => {
  return request.post(`${api.login.sms}/${data}`, {});
}

//找回密码发送验证码
export const forgetPassSms = (data) => {
  return request.post(`${api.login.forgetPassSms}/${data}`,{})
}

//忘记密码
export const forgetPassword = (data) => {
  return request.post(api.login.forgetPassword,data)
}

//修改密码
export const updatePassword = (data) => {
  return request.post(api.login.updatePassword,data);
}

//修改密码发送验证码
export const updatePasswordSms = (data) => {
  return request.post(`${api.login.changeSms}/${data}`,{})
}

//修改个人信息
export const editUserInfo = (data) => {
  return request.post(api.login.userInfo, data);
}

//修改个人信息发送验证码
export const editUserInfoSms = (data) => {
  return request.post(`${api.login.userInfoSms}/${data}`,{})
}

//获取用户信息
export const getUserInfo = (data) => {
  return request.post(api.login.info, data);
}