export const login = {
	login: "/sys/login", //登录
	sms: "/loginSms", //登录发送验证码
	register: "/sys/register", //注册
	logout: "/sys/user/logout", //退出
	forgetPassword: "/sys/forgetPassword", //忘记密码
	updatePassword: "/sys/user/updatePassword", //修改密码
	forgetPassSms: "/forgetPassSms", //忘记密码发送短信验证码
	info: "/sys/user/info", //获取个人信息接口
	userInfo: "/sys/user/updateUserInfo", //修改个人信息
	userInfoSms: "/updatePersonalInfo", //
	changeSms: "/updatePass", //修改密码发送验证码
}

export const system = {
	list: "/sys/user/accountManageList", //系统管理账号管理
	subMange: "/sys/user/subordinateManageList", //下级账号管理接口
	subAdd: "/sys/user/subordinateAdd", //下级账号添加接口
	audit: "/sys/user/auditHospital", //审核医院接口
	password: "/sys/user/resetPass", //重置密码
	enable: "/sys/user/enableDisable", //启用/禁用
	detail: "/sys/user/auditHospitalInfo", //账号详情
}

export const address = {
	all: "/v1/areaInfo/tree",
	address: "/v1/areaInfo/list"
}
export const menu = {
	menu: "/sys/user/getUserInfo" //菜单接口
}

export const imgesUp = {
	imgesUp: "/sys/oss/upload" // 图片上传
}
export const people = {
	list: "/staff/list", //人员列表
	search: "/staff/selectById", //根据人员id查询人员
	edit: "/staff/update", //修改人员信息
	add: "/staff/save", //添加人员
	enable: "/staff/updateState", //修改人员状态/启用、禁用
	testReport: "/staff/testReport", //医院id查询个人剂量检测报告
	export: "/staff/list/export", //导出人员信息
	code: "/v1/equipment/generateQr", //查看二维码
	chart: "/staff/descDoseByStaffId", //近二十条数据图表
	del: "/staff/delStaffDoseById", //删除个人剂量
}

export const office = {
	list: "/officee/list", //科室列表
	info: "/officee/selectById", //id查询科室信息
	update: "/officee/update", //修改科室信息
	add: "/officee/save", //添加科室
	upload: "/officee/saveOfficeeFile", //添加科室相关制度
	delRule: "/officee/deleteOfficeeFile", //id删除科室相关制度
	rule: "/officee/officeeFileAllList", //获取相关制度列表
	operation: "/officee/allOperFileList", //获取操作制度
}

export const hospital = {
	detail: "/v1/hospital/info", //医院详情
	list: "/v1/hospital/list", //医院列表
	upload: "/v1/hospital/file/save", //医院的附件上传
	before: "/sys/files/list", //获取许可证过往
	delete: "/sys/files/delete/", // 个人剂量报告和设备检测报告删除
	cityPrefectureInfo: "/v1/hospital/cityPrefectureInfo", // 区县和市信息接口
	export: "/v1/hospital/cityPrefectureInfo/export", //导出本市和区县信息
	dose: "/v1/hospital/info/getMoreByHospitalId", //获取个人剂量列表
	device: "/v1/hospital/info/getMoreByHospitalId", //设备检测列表
	zip: "/v1/hospital/info/getMoreForDownForZip", //打包下载
	edit: "/v1/hospital/updateBusinessLicense", //修改营业执照
}

export const notification = {
	list: "/notification/list", //分页查询通告列表
	detail: "/notification/selectById", //根据id查询通告信息
	add: "/notification/save", //添加通告信息
	edit: "/notification/update", //修改通告信息
	enable: "/notification/updateState", //通告启用/禁用
	tree: "/staff/selectAreaUserTree", //查询通告指定范围
}

export const message = {
	list: "/remind/list", //分页查询消息列表
	detail: "/remind/list/selectById/{id}", //根据id查询消息详情
	search: "/remind/selectAllUnread", //查询所有未读消息列表
	read: "/remind//updateState", //根据消息id修改消息为已读
	have: "/remind/selectAllUnreadCount", //是否有未读消息
}

export const knowledgeBase = {
	list: "/v1/knowledgeBase/list", //知识库列表
	add: "/v1/knowledgeBase/save", //知识库添加
	edit: "/v1/knowledgeBase/update", //知识库编辑
	enable: "/v1/knowledgeBase/updateState", //知识库禁用|启用
	detail: "/v1/knowledgeBase/info/" //知识库详情
}

export const rule = {
	list: "/v1/institution/list", //制度列表
	add: "/v1/institution/save", //制度添加
	edit: "/v1/institution/update", //制度编辑
	del: "/v1/institution/delete/", //制度删除
	detail: "/v1/institution/info/" //制度详情
}

export const equipment = {
	list: "/v1/equipment/list", //设备列表
	add: "/v1/equipment/save", //设备添加
	del: "/v1/equipment/institution/delete", //制度文件删除
	info: "/v1/equipment/info", //设备详情
	updata: "/v1/equipment/update", //设备编辑
	disable: "/v1/equipment/updateState", //设备启用禁用
	save: "/v1/equipment/articles/save", // 放射防护用品添加
	lost: "/v1/equipment/articles/list", // 防护的信息列表
	oper: "/v1/equipment/articles/info", //放射防护用品信息详情
	enble: "/v1/equipment/articles/updateState", //防护用品禁用or启用
	jcList: "/v1/equipment/checkRecord/all/list", // 检测记录列表(全部分类)
	roet: "/v1/equipment/checkRecord/list", // 检测记录列表
	nerp: "/v1/equipment/checkRecord/save", //检测记录添加
	jupd: "/v1/equipment/checkRecord", // 检测记录详情
	kosw: "/v1/equipment/maintainRecord/list", //维修记录列表
	wlost: "/v1/equipment/maintainRecord/save", //维修记录添加
	export: "/v1/equipment/list/export", //导出设备信息
	fenceExport: "/v1/equipment/articles/export", //导出放射防护信息
}

export const facility = {
	list: "/v1//equipment/list", //设备列表
	add: "/v1//equipment/save" //设备添加
}

export const radioactiveSourcesSeal = {
	list: "/v1/radioactiveSourcesSeal/list", // 放射源（密封）列表
	save: "/v1/radioactiveSourcesSeal/save", // 放射源（密封）添加
	info: "/v1/radioactiveSourcesSeal/info/", // 放射源（密封）详情
	updateState: "/v1/radioactiveSourcesSeal/updateState", // 放射源（密封）启用|禁止|回收
	recordList: "/v1/radioactiveSourcesSeal/record/list", // 放射源（密封）出库记录列表
	recordSave: "/v1/radioactiveSourcesSeal/record/save", // 放射源（密封）出库记录添加
}

export const radioactiveSources = {
	list: "/v1/radioactiveSources/list", // 放射源（非密封）列表
	save: "/v1/radioactiveSources/save", // 放射源（非密封）添加
	info: "/v1/radioactiveSources/info/", // 放射源（非密封）详情
	updateState: "/v1/radioactiveSources/updateState", // 放射源（非密封）启用|禁止|回收
	recordList: "/v1/radioactiveSources/record/list", // 耗材出库记录列表
	recordSave: "/v1/radioactiveSources/record/save", // 耗材出库记录添加
	recordDelete: "/v1/radioactiveSources/record/delete/", // 耗材出库记录删除
	equipmentRecordList: "/v1/radioactiveSources/equipmentRecord/list", // 设备出库记录列表
	equipmentRecordSave: "/v1/radioactiveSources/equipmentRecord/save", // 设备出库记录添加
	equipmentRecordDelete: "/v1/radioactiveSources/equipmentRecord/delete/", // 设备出库记录删除
	exportSealed: "/v1/radioactiveSourcesSeal/export", //导出密封数据
	exportUnsealed: "/v1/radioactiveSources/export", //导出非密封数据
}

export const qualityControlEquipmen = {
	list: "/qualityControlEquipmen/list", // 分页查询质量控制设备列表
	save: "/qualityControlEquipmen/save", // 保存质量控制设备信息
	update: "/qualityControlEquipmen/update", // 修改质量控制设备信息
	selectById: "/qualityControlEquipmen/selectById/", // 根据id查询质量控制设备列表
	updateState: "/qualityControlEquipmen/updateState", // 启用/禁用/报废质量控制设备
}

export const support = {
	detail: "/sys/config/helpSupport", //技术支持
}

// 公共接口
export const publich = {
	selectOrg: "/qualityControlEquipmen/selectOrg",// 获取当前登录人省，市，区县，医院，科室数据
}
