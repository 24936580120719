// 资讯
import * as request from '@/utils/request';
import * as api from '@/config/api'

// 获取知识库列表
export const knowledgeList = (data) => {
  return request.post(api.knowledgeBase.list, data)
}

// 添加知识库
export const saveKnowledge = (data) => {
  return request.post(api.knowledgeBase.add, data)
}

// 知识库详情
export const knowledgeDetails = (data) => {
  return request.post(`${api.knowledgeBase.detail}${data}`)
}

// 知识库禁用|启用
export const knowledgeEnable = (data) => {
  return request.post(api.knowledgeBase.enable, data)
}

// 知识库编辑
export const knowledgeUpdate = (data) => {
  return request.post(api.knowledgeBase.edit, data)
}

// 添加制度
export const ruleAdd = (data) => {
  return request.post(api.rule.add, data)
}

// 查询制度列表
export const ruleList = (data) => {
  return request.post(api.rule.list, data)
}

// 制度详情
export const ruleDetails = (data) => {
  return request.post(`${api.rule.detail}${data}`)
}

// 制度删除
export const ruleDelete = (data) => {
  return request.post(`${api.rule.del}${data}`)
}

//分页查询通告列表
export const notificationList = (data) => {
  return request.post(api.notification.list, data)
}

//根据id查询通告信息
export const notificationSelectById = (data) => {
  return request.post(`${api.notification.detail}/${data}`)
}

//添加通告信息
export const notificationSave = (data) => {
  return request.post(api.notification.add, data)
}

//修改通告信息
export const notificationEdit = (data) => {
  return request.post(api.notification.edit, data)
}

//通告启用/禁用
export const notificationEnable = (data) => {
  return request.post(api.notification.enable, data)
}

//查询节点tree
export const notificationTree = (data) => {
  return request.post(api.notification.tree, data)
}