//此功能实现文档预览

export const preview = (url) => {
  if (url) {
    //此方法为收费方法
    // window.open("https://view.xdocin.com/view?src=" + encodeURIComponent(url) + "&watermark=" + encodeURIComponent("九江市放射卫生管理系统"));
    //此方法区分文档类型
    window.open("#/doc?previewUrl=" +url);
  }else {
    return;
  }
}