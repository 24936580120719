//区域接口
import * as request from '@/utils/request';
import * as api from '@/config/api';

//全部省市区
export const getAddress = (data) => {
  return request.post(api.address.all, data);
}

//单独查询
export const getNextAddress = (data) => {
  return request.post(api.address.address, data)
}