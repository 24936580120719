//医院信息
import * as request from '@/utils/request';
import * as api from '@/config/api';

//医院详情
export const getDetail = (data) => {
	return request.post(api.hospital.detail, data);
}

//医院附件上传
export const uploadFile = (data) => {
	return request.post(api.hospital.upload, data);
}

//获取过往上传
export const getBeforeImg = (data) => {
	return request.post(api.hospital.before, data);
}

// 个人剂量报告和设备检测报告删除
export const deleteFile = (data) => {
	return request.post(`${api.hospital.delete}${data}`);
}

// 区县和市信息接口
export const cityPrefectureInfo = () => {
	return request.post(api.hospital.cityPrefectureInfo);
}
// 导出本市信息
export const exportCityInfo = (data, option) => {
	return request.post(api.hospital.export, data, option);
}

// 获取个人剂量列表
export const getDoseList = (data) => {
	return request.post(api.hospital.dose, data);
}

// 获取设备检测列表
export const getDeviceList = (data) => {
	return request.post(api.hospital.device, data);
}

//打包下载
export const downAllZip = (data, option) => {
	return request.post(api.hospital.zip, data, option);
}

//上传营业执照
export const uploadBusinessLicense = (data) => {
	return request.post(api.hospital.edit, data);
}