<template>
  <div class="message">
    <div class="message-header">
      {{ now }}到期提醒：
    </div>
    <div class="list">
      <div class="list-content" v-for="(item, index) in tableData" :key="index">
        <span @click="setDetail(item)">{{ item.msgTitle }}</span>
      </div>
      <pagination :page-total-elements="total" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/index";
import {getDay} from "@/utils/utils";
import Pagination from "@/components/pagination";
import {setEquipmentId} from "@/utils/localStorage";
import {preview} from "@/utils/preview";

export default {
  name: "message",
  components: {
    Pagination
  },
  data() {
    return {
      now: getDay(),
      tableData: [],
      total: 0,
      form: {
        msgType: 1,
        msgState: 0,
        currPage: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    setDetail(val) {
      this.editMessageRead(val.id);
      let url = "";
      if(val.dataType == '1') {
        url = this.$router.resolve({
          path: '/detail',
          query:{id: val.dataId}
        });
      } else if(val.dataType == '2') {
        url = this.$router.resolve({
          path: "qualityControl/qualityControl"
        });
      } else if(val.dataType == '3') {
        setEquipmentId("equipmentId",val.dataId);
        url = this.$router.resolve({
          path: "facilityEdit",
          query: {
            pitchIndex: 1
          }
        });
      } else if(val.dataType == '4') {
        url = this.$router.resolve({
          path:'peopleManagement/edit/' + val.dataId,
        });
      } else if(val.dataType == '5') {
        setEquipmentId("equipmentId",val.dataId);
        url = this.$router.resolve({
          path: "facilityEdit",
          query: {
            pitchIndex: 2
          }
        });
      } else if(val.dataType == '6' || val.dataType == '7') {
        preview(val.dataUrl ? val.dataUrl : val.filePath)
      }
      window.open(url.href, '_blank');
    },
    //切换页码
    handleCurrentChange(val) {
      this.form.currPage = val;
      this.getList();
    },
    //未读消息
    getList() {
      api.message.getNotice(this.form).then((res) => {
        if (res.code === 200) {
          let result = res.data;
          this.tableData = result.list;
          this.total = result.totalCount;
        }
      })
    },
    // 修改消息为已读
    editMessageRead(id) {
      api.notice.editMessage(id).then((res) => {
        if (res.code === 200) {

        }
      })
    }
  },
  created() {
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
  .message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    .message-header {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
    }
    .list {
      margin-bottom: 30px;
      .list-content {
        min-height: 25px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        text-decoration: underline;
        color: #1579FB;
        cursor: pointer;
      }
    }
  }
</style>