
// 缓存当前选择的医院或者科室 key 1: 医院  2: 科室
export const setOrganizationInfo = (val) => {
    localStorage.setItem("organizationInfo", val)
}

export const getOrganizationInfo = () => {
    return JSON.parse(localStorage.getItem("organizationInfo"))
}

export const removeOrganizationInfo = (val) => {
    localStorage.removeItem("organizationInfo")
}