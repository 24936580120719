<template>
  <div class="dialog-header">
    <div class="header-left"></div>
    <div class="header" :style="{height: height + 'px', fontSize: fontSize + 'px'}">
      {{ name }}
    </div>
    <div class="header-right">
      <i v-if="showClose" @click="headerClose" class="el-icon-close"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialogHeader",
  props:{
    height: {
      type: String,
      default: '46'
    },
    fontSize: {
      type: String,
      default: '18'
    },
    name: {
      type: String,
      require: true
    },
    showClose: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {

    }
  },
  methods: {
    headerClose() {
      this.$emit("headerClose")
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-header {
    width: 100%;
    padding: 0px 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1579FB;
    box-shadow: 0px 4px 10px 3px rgba(136, 130, 116, 0.15);
    border-radius: 10px 10px 0px 0px;
    .header-left {
      width: 30px;
    }
    .header {
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FEFEFF;
    }
    .header-right {
      width: 30px;
      display: flex;
      justify-content: flex-end;
      font-size: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
</style>