// 质量控制设备管理
import * as request from '@/utils/request';
import * as api from '@/config/api'

// 分页查询质量控制设备列表
export const qualityControlEquipmenList = (data) => {
    return request.post(api.qualityControlEquipmen.list, data)
}

// 保存质量控制设备信息
export const qualityControlEquipmenSave = (data) => {
    return request.post(api.qualityControlEquipmen.save, data)
}

// 修改质量控制设备信息
export const qualityControlEquipmenUpdate = (data) => {
    return request.post(api.qualityControlEquipmen.update, data)
}

// 根据id查询质量控制设备列表
export const qualityControlEquipmenSelectById = (data) => {
    return request.post(`${api.qualityControlEquipmen.selectById}${data}`)
}

// 启用/禁用/报废质量控制设备
export const qualityControlEquipmenUpdateState = (data) => {
    return request.post(api.qualityControlEquipmen.updateState, data)
}