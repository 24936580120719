
import dbclick from "./module/dbclick";

const install = function(Vue) {
  Vue.directive('preventReClick', dbclick);
}


if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}


export default install