// 设备接口
import * as request from '@/utils/request';
import * as api from '@/config/api';
import {imgesUp} from "@/config/api";

// 设备列表
export const getList = (data) => {
    return request.post(api.equipment.list, data);
  }
//设备添加
  export const add = (data) => {
    return request.post(api.equipment.add, data);
  }
  //操作制度上传
export const uploadSystem = (data) => {
  return request.post(api.imgesUp.imgesUp, data);
}
//制度文件删除
  export const delFile = (data) => {
    return request.post(`${api.equipment.del}/${data}`, {});
  }
//设备详情
  export const getDetail = (data) => {
    return request.post(`${api.equipment.info}/${data}`, {});
  }
//设备编辑
  export const edit = (data) => {
    return request.post(api.equipment.updata, data);
  }
// 放射防护用品添加
  export const addFence = (data) => {
    return request.post(api.equipment.save, data);
  }
  //设备启用or禁用
export const disable = (data) => {
  return request.post(api.equipment.disable, data);
}
// 防护的信息列表
  export const getFenceList = (data) => {
    return request.post(api.equipment.lost, data);
  }
//放射防护用品信息详情
  export const getFenceDetail = (data) => {
    return request.post(`${api.equipment.oper}/${data}`, {});
  }
  //放射防护用品禁用
  export const enbleFence = (data) => {
    return request.post(api.equipment.enble, data)
  }
// 检测记录列表(全部分类)
  export const getDetectionAllList = (data) => {
    return request.post(api.equipment.jcList, data);
  }
// 检测记录列表
  export const getDetectionList = (data) => {
    return request.post(api.equipment.roet, data);
  }
//检测记录添加
  export const addDetection = (data) => {
    return request.post(api.equipment.nerp, data);
  }
// 检测记录详情
  export const getDetectionDetail = (data) => {
    return request.post(`${api.equipment.jupd}/${data}`, {});
  }
//维修记录列表
  export const getServiceList = (data) => {
    return request.post(api.equipment.kosw, data);
  }
//维修记录添加
  export const addService = (data) => {
    return request.post(api.equipment.wlost, data);
  }

// 导出设备信息
export const exportEquipment = (data, option) => {
  return request.post(api.equipment.export, data, option);
}

//导出放射防护信息
export const exportFence = (data, option) => {
  return request.post(api.equipment.fenceExport, data, option);
}