// 放射源（密封/非密封）源管理
import * as request from '@/utils/request';
import * as api from '@/config/api'

// 放射源（密封）列表
export const sourcesSealList = (data) => {
    return request.post(api.radioactiveSourcesSeal.list, data)
}

// 放射源（密封）添加
export const sourcesSealSave = (data) => {
    return request.post(api.radioactiveSourcesSeal.save, data)
}

// 放射源（密封）详情
export const sourcesSealInfo = (data) => {
    return request.post(`${api.radioactiveSourcesSeal.info}${data}`)
}

// 放射源（密封）启用|禁止|回收
export const sourcesSealUpdateState = (data) => {
    return request.post(api.radioactiveSourcesSeal.updateState, data)
}

// 放射源（密封）出库记录列表
export const sourcesSealRecordList = (data) => {
    return request.post(api.radioactiveSourcesSeal.recordList, data)
}

// 放射源（密封）出库记录添加
export const sourcesSealRecordSave = (data) => {
    return request.post(api.radioactiveSourcesSeal.recordSave, data)
}

// 放射源（非密封）列表
export const sourcesList = (data) => {
    return request.post(api.radioactiveSources.list, data)
}

// 放射源（非密封）添加
export const sourcesSave = (data) => {
    return request.post(api.radioactiveSources.save, data)
}

// 放射源（非密封）详情
export const sourcesInfo = (data) => {
    return request.post(`${api.radioactiveSources.info}${data}`)
}

// 放射源（非密封）启用|禁止|回收
export const sourcesUpdateState = (data) => {
    return request.post(api.radioactiveSources.updateState, data)
}

// 耗材出库记录列表
export const sourcesRecordList = (data) => {
    return request.post(api.radioactiveSources.recordList, data)
}

// 耗材出库记录添加
export const sourcesRecordSave = (data) => {
    return request.post(api.radioactiveSources.recordSave, data)
}

// 耗材出库记录删除
export const sourcesRecordDelete = (data) => {
    return request.post(api.radioactiveSources.recordDelete, data)
}

// 设备出库记录列表
export const sourcesEquipmentRecordList = (data) => {
    return request.post(api.radioactiveSources.equipmentRecordList, data)
}

// 设备出库记录添加
export const sourcesEquipmentRecordSave = (data) => {
    return request.post(api.radioactiveSources.equipmentRecordSave, data)
}

// 设备出库记录删除
export const sourcesEquipmentRecordDelete = (data) => {
    return request.post(api.radioactiveSources.equipmentRecordDelete, data)
}

// 导出密封管理
export const exportSealed = (data, option) => {
    return request.post(api.radioactiveSources.exportSealed, data, option);
}

// 导出非密封管理
export const exportUnsealed = (data, option) => {
    return request.post(api.radioactiveSources.exportUnsealed, data, option);
}