//系统管理
import * as request from '@/utils/request';
import * as api from '@/config/api'

//账号管理
export const getList = (data) => {
  return request.post(api.system.list, data);
}

//下级账号接口
export const getSubList = (data) => {
  return request.post(api.system.subMange, data);
}

//下级账号添加
export const addAccount = (data) => {
  return request.post(api.system.subAdd, data);
}

//审核账号
export const auditAccount = (data) => {
  return request.post(api.system.audit, data);
}

//重置密码
export const resetPassword = (data) => {
  return request.post(api.system.password, data);
}

//启用or禁用
export const enableAccount= (data) => {
  return request.post(api.system.enable, data);
}

//账号详情
export const getAccountDetail= (data) => {
  return request.post(`${api.system.detail}/${data}`, {});
}