/**
 * @flie 防止重复提交
 *
 * */


//限制重复提交模块

export default{
  update: function(el, binding, vNode, oldVnode){
    el.addEventListener('click', () => {
      if(!el.disabled){
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, 1000)
      }
    })
  }
}