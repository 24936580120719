<template>
    <div class="pagination">
        <el-pagination
            v-if='pageTotalElements > 0'
            background
            :page-size="paginations.pageSize"
            :layout="paginations.layout"
            :total="pageTotalElements"
            :current-page='paginations.pageIndex'
            @current-change='handleCurrentChange'>
        </el-pagination>
    </div>
</template>


<script>
  export default {
      name:'pagination',
      data(){
          return {
            paginations: {
                pageIndex: 1,  // 当前位于哪页
                pageSize: 10,   // 1页显示多少条
                layout: "total, prev, pager, next"   // 翻页属性
            },
          }
      },
      props:{
        pageTotalElements: {
            /** 列表总和 */
            type: Number,
            default: 0
        }
      },
      created(){

      },
      mounted(){

      },
      methods:{ 
        // 上下分页 pageIndex
        handleCurrentChange(page){
            this.$emit('handleCurrentChange',page);
        },
      }
  }
</script>

<style lang="scss" scoped>
    .pagination{
        text-align: center;
        padding: 10px 18px;
        :deep(.el-pagination__total) {
            font-size: 14px;
            font-weight: 400;
            color: #3E3A39;
        }
    }
</style>
