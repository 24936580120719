<template>
  <div class="layout">
    <app-header></app-header>
    <app-menu></app-menu>
    <app-main></app-main>
  </div>
</template>
<script>
import AppHeader from './components/AppHeader'
import AppMenu from './components/AppMenu'
import AppMain from './components/AppMain'
import Vue from 'vue'
export default {
  name: 'Layout',
  components: {
    AppHeader,
    AppMenu,
    AppMain
  },
}
</script>
<style lang="scss" scoped>
.layout {
  height: 100%;
}
</style>