import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoading: false
  },
  //修改store入口
  mutations: {
    changeLoading(state) {
      state.showLoading = true;
    },
    disableLoading(state) {
      state.showLoading = false;
    }
  },
  actions: {

  },
  getters: {
  },
  modules: {
  }
})
