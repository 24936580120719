//消息接口
import * as request from '@/utils/request';
import * as api from '@/config/api'

//未读消息
export const unreadMessage = (data) => {
  return request.post(api.message.search, data);
}

//修改消息为已读
export const editMessage = (data) => {
  return request.post(`${api.message.read}/${data}`, {});
}

//是否有未读消息
export const haveMessage = (data) => {
  return request.post(api.message.have, data);
}