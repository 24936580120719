//首页通告
import * as request from '@/utils/request';
import * as api from '@/config/api';

//通告列表
export const getNotice = (data) => {
  return request.post(api.message.list, data);
}

//通告详情
export const getDetail = (data) => {
  return request.post(`${api.notification.detail}/${data.id}`, {});
}