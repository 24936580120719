// 人员管理
import * as request from '@/utils/request';
import * as api from '@/config/api'


// 人员列表
export const getList = (data) => {
	return request.post(api.people.list, data);
}
// 查看
export const lookFrom = (data) => {
	return request.post(`${api.people.search}/${data}`, {});
}
// 编辑
export const edit = (data) => {
	return request.post(api.people.edit, data);
}
// 新增
export const add = (data) => {
	return request.post(api.people.add, data);
}

// 禁用启用
export const enable = (data) => {
	return request.post(api.people.enable, data);
}
// 个人剂量查看  
export const testReport = (data) => {
	// return request.post(`${api.people.testReport}/${data}`, {}); 
	return request.post(api.people.testReport, data);
}
// 导出人员信息
export const exportUserInfo = (data, option) => {
	return request.post(api.people.export, data, option);
}
//查看二维码
export const getUserCode = (data) => {
	return request.post(`${api.people.code}/${data}`, {});
}

//图表
export const getChart = (data) => {
	return request.post(`${api.people.chart}/${data}`, {});
}

//删除个人剂量
export const delDosage = (data) => {
	return request.post(`${api.people.del}/${data}`, {});
}